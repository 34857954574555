<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-editsupplier" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Edit supplier :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="editUsersupplier">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">
                        <b-form-group id="name" label="Supplier Name:" label-for="name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input id="name" v-model="form.name" placeholder="Enter Display Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="email" label="Email:" label-for="email">
                            <validation-provider #default="{ errors }" name="email" rules="required">
                                <b-form-input id="email" v-model="form.email" placeholder="example@domain.com">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="phone" label="Phone:" label-for="phone">
                            <validation-provider #default="{ errors }" name="phone" rules="required">
                                <b-form-input id="phone" v-model="form.phone" type="number" placeholder="123-456-1234">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="website" label="Website:" label-for="website">
                            <validation-provider #default="{ errors }" name="website" rules="required">
                                <b-form-input id="website" v-model="form.website" placeholder="Enter website">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Update
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import User from '../../../Helpers/User'
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, name, email, phone, website,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent, BFormCheckboxGroup
    },
    directives: {
        Ripple,
    },
    props: {
        editsupplier: {
            type: Object,
            required: true,
        }
    },
    watch: {
        editsupplier() {
            this.form.id = this.editsupplier.id;
            this.form.name = this.editsupplier.name;
            this.form.email = this.editsupplier.email;
            this.form.phone = this.editsupplier.phone;
            this.form.website = this.editsupplier.website;

        }
    },
    data() {
        return {
            required, name, email, phone, website,
            form: {
                id: '',
                name: '',
                email: '',
                phone: '',
                website: '',
            },
        }
    },

    mounted() {
        // // fetch data from API
        // axios.get('/api/roles/1')
        //     .then(response => {
        //         // assign response data to form object
        //         this.form = response.data;
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    },
    methods: {
        checkPermission(permission) {

            return User.checkPermission(permission)
        },

        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        clearForm() {
                this.form.name = '',
                this.form.email = '',
                this.form.phone = '',
                this.form.website = ''
        },
        onSubmit(event) {
            this.$refs.editUsersupplier.validate().then(success => {
                if (success) {
                    // const supplierId = this.supplierId;
                    this.loader = true
                    axios({
                        method: 'put',
                        url: process.env.VUE_APP_API + '/super-supplier/super-supplier',
                        data: {
                            'id': this.form.id,
                            'name': this.form.name,
                            'email': this.form.email,
                            'phone': this.form.phone,
                            'website': this.form.website,
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                            this.$emit('refresh')
                            this.closeSidebar()
                                this.loader = false
                                this.$toast
                                    ({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Supplier Updated',
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'danger',
                                        },
                                    },
                                        {
                                            position: 'top-right'
                                        }) 
                                        this.clearForm()
                            }
                            else {
                                this.loader = false
                                this.$toast
                                    ({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Failed',
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'danger',
                                        },
                                    },
                                        {
                                            position: 'top-left'
                                        })
                            }
                        })
                        .catch((error) => {
                            this.loader = false
                            console.log(error);
                        });

                }
            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-editsupplier')
            this.clearForm()
        },
    },
    computed: {
    }

}
</script>
