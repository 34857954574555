var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-AddNewsupplier","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" Add New Supplier : ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"addUsersuppliers"},[_c('b-form',{staticClass:"m-2",attrs:{"id":"FORMID"},on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-form-group',{attrs:{"id":"name","label":"Supplier Name:","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Enter Supplier Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"email","label":"Email:","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"example@domain.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"phone","label":"Phone:","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","type":"number","placeholder":"123-456-1234"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"website","label":"Website:","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"website","placeholder":"Enter website"},model:{value:(_vm.form.website),callback:function ($$v) {_vm.$set(_vm.form, "website", $$v)},expression:"form.website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex mb-2 ml-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"form":"FORMID","variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" Submit ")]),_c('b-button',{staticClass:"mr-2",attrs:{"type":"reset","variant":"danger","form":"FORMID"}},[_vm._v(" Reset ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","form":"FORMID"},on:{"click":_vm.closeSidebar}},[_vm._v(" Cancel ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }